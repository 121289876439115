import React, { useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Head from 'next/head';
import getConfig from 'next/config';
import { SWRConfig } from 'swr';
import { getDuration } from 'apicache';
import classNames from 'classnames';

import { loadFront } from 'redux/modules/front';
import {
  setPageView,
  setSection,
} from 'redux/modules/shared';

import { buildLiveVideoObjectLD } from 'lib/LDJson';
import { layout as servicesLayoutPropType } from 'lib/CustomPropTypes/services';
import { ScheduleDataPropType } from 'lib/CustomPropTypes/scheduleData';
import { navbar, NAVBAR_THEME } from 'lib/navbar';
import { setLinkHeaders } from 'lib/setLinkHeaders';
import { getBroadcastSchedulesDataUrl, BROADCAST_SCHEDULES_ENDPOINT } from 'lib/scheduleUtils';
import { mapBentoAPIEndpointsToSWRUrls } from 'lib/mapBentoAPIEndpointsToSWRUrls';
import { STREAM_KEYS, LIVE_VIDEO_STREAM_JSON_LD_METADATA } from 'lib/liveVideo';
import { FeatureFlagContext } from 'lib/ContextTypes';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { FLUID_WIDTH_FRONT } from 'lib/brandFeatures';

import AdsBundle from 'components/AdsBundle';
import { ThemesCssBundle } from 'components/ThemesCssBundle';
import { AnalyticsLaunchScripts } from 'components/AnalyticsLaunchScripts';
import { HeaderAndFooter } from 'components/services/HeaderAndFooter';
import Curated from 'components/TVE/Curated';
import Info from 'components/VideoLive/Info';
import { ScheduleLive } from 'components/VideoLive/ScheduleLive';
import Banner from 'components/VideoLive/Banner';
import Meta from 'components/Meta';
import { GlobalMetadata } from 'components/PageMetadata';
import { CoreVideoPlayer } from 'components/CoreVideoPlayer';
import { VideoContainer } from 'components/VideoContainer';
import { VideoControlRack } from 'components/VideoContainer/VideoControlRack';
import { TapToUnmuteButton } from 'components/VideoContainer/TapToUnmuteButton';
import IconfontStyleTag from 'components/IconfontStyleTag';
import { OmegaVideoPlayer } from 'components/OmegaVideoPlayer';
import { OmegaFeatureFlagSwitch } from 'components/OmegaVideoPlayer/OmegaSwitch';

import 'assets/styles/main.scss';
import 'assets/styles/toolkit.scss';
import globalContainerStyles from '../globalContainerStyles.module.scss';
import './styles.themed.scss';

const pageView = 'front';

export const navbarConfig = {
  /**
   * Returns the theme for the navbar based on the vertical type.
   * @param {object} args
   * @param {VerticalType} args.vertical
   */
  theme({ vertical }) {
    if (vertical === 'noticias') {
      return NAVBAR_THEME.VERTICAL;
    }
    return NAVBAR_THEME.DARK;
  },
};

/**
 * Maps the state to props for the component.
 * @param {object} args
 * @param {object} args.shared
 * @param {boolean} args.shared.isChromeless
 * @param {object} args.video
 * @param {boolean} args.video.error
 */
const mapStateToProps = ({
  shared, video,
}) => ({
  isChromeless: shared.isChromeless,
  videoError: video.error,
});

const verticalLiveVideoKeyMap = {
  news: STREAM_KEYS.NEWS_NOW,
  today: STREAM_KEYS.TODAY_ALL_DAY,
  MSNBC: STREAM_KEYS.MSNBC_TVE,
  noticias: STREAM_KEYS.NOTICIAS_AHORA,
};

const slugToFrontNameMap = {
  'noticias/noticias-telemundo-ahora': 'noticias-telemundo-ahora',
};

/**
 * The VideoLivePage component.
 * @param {object} props
 * @param {boolean} props.isChromeless
 * @param {HFS_Layout} props.layout
 * @param {VerticalType} props.vertical
 * @param {Video_FallbackData} props.ramenBentoAPISWRFallbackData - object of verticals with array of scheduleItems, e.g. { NEWS_NOW: scheduleItems:[endDateTime: "", startDateTime: "", title: ""] }
 * @param {string} props.fullUrl
 */
function VideoLivePage(props) {
  const {
    isChromeless,
    layout,
    vertical,
    ramenBentoAPISWRFallbackData,
    fullUrl,
  } = props;

  const liveVideoKey = verticalLiveVideoKeyMap[vertical];

  const {
    title,
    description,
    thumbnailUrl,
    uploadDate,
    author,
    startDate,
    videoAssets,
  } = LIVE_VIDEO_STREAM_JSON_LD_METADATA[liveVideoKey];

  const swrFallbackData = mapBentoAPIEndpointsToSWRUrls(ramenBentoAPISWRFallbackData);

  const curatorLiveVideo = useContext(FeatureFlagContext)['curator-live-video'];

  const isFluidWidthPage = getFeatureConfigForBrand(
    FLUID_WIDTH_FRONT,
    vertical,
  );

  const content = (
    <>
      <GlobalMetadata />
      <Meta
        title={title}
        description={description}
      />

      {!curatorLiveVideo && (
        <section className="video-live-original">
          <div className="layout-grid-container">
            <div className="video-live__inner">
              <div className="video-live__stage">
                <OmegaFeatureFlagSwitch
                  ifOmega={(
                    <div className="video-live__core-video-container">
                      <OmegaVideoPlayer
                        stickyEnabled
                        autoplay
                        mutedAutoplay
                        stream={liveVideoKey}
                      />
                    </div>
                  )}
                >
                  <div className="video-live__core-video-container">
                    <VideoContainer stickyEnabled>
                      <TapToUnmuteButton />
                      <CoreVideoPlayer stream={liveVideoKey} autoplay mutedAutoplay />
                      <VideoControlRack />
                    </VideoContainer>
                  </div>
                </OmegaFeatureFlagSwitch>
                <div className="video-live__info-container">
                  <Info />
                </div>
              </div>

              <div className="video-live__schedule-container">
                <ScheduleLive />
              </div>
            </div>
          </div>

          <Banner />
        </section>
      )}

      <div
        className={classNames({
          'layout-grid-container mb8': !isFluidWidthPage,
        })}
        data-testid="front-container"
      >
        <div className={classNames({ 'layout-grid-item': !isFluidWidthPage })}>
          <Curated isFluidWidthPage={isFluidWidthPage} />
        </div>
      </div>
    </>
  );

  const wrappedContent = isChromeless ? (
    content
  ) : (
    <HeaderAndFooter layout={layout}>{content}</HeaderAndFooter>
  );

  return (
    <SWRConfig
      value={{
        fallback: swrFallbackData,
      }}
    >
      <Head>
        <IconfontStyleTag />
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(buildLiveVideoObjectLD({
              title,
              description,
              thumbnailUrl,
              uploadDate,
              author,
              startDate,
              includeMainEntityOfPage: true,
              url: fullUrl,
              videoAssets,
            })),
          }}
        />
      </Head>
      <ThemesCssBundle vertical={vertical} />

      <div id="content" className={globalContainerStyles.container}>
        {wrappedContent}
      </div>

      <AnalyticsLaunchScripts />
      <AdsBundle />
    </SWRConfig>
  );
}

/**
 * Fetches initial props for the VideoLivePage component.
 * @param {object} ctx
 * @param {object} ctx.res
 * @param {object} ctx.store
 */
VideoLivePage.getInitialProps = async (ctx) => {
  const {
    res: {
      locals: {
        vertical,
        cacheRequest,
        fullUrl,
      },
    },
    store,
  } = ctx;

  // not using `asPath` here, since `asPath` also includes query params (e.g. `/now?query=params`)
  const slugPath = new URL(fullUrl).pathname.replace(/^\//, '');
  const frontFromPath = slugToFrontNameMap[slugPath] || slugPath;
  const { serverRuntimeConfig: { API_CACHE_TTL, SCHEDULE_DATA_API_CACHE_TTL } } = getConfig();

  const broadcastSchedulesDataUrl = getBroadcastSchedulesDataUrl();
  const scheduleDataTTL = getDuration(SCHEDULE_DATA_API_CACHE_TTL || API_CACHE_TTL);
  let scheduleData;

  const broadcastSchedulesDataFetch = cacheRequest({
    url: broadcastSchedulesDataUrl,
    ttlms: scheduleDataTTL,
    cacheKey: ':BroadcastSchedules:',
  })
    .then(({ data }) => { scheduleData = data; })
    .catch(() => { /* no op */ });

  const promises = [
    store.dispatch(setPageView(pageView)),
    store.dispatch(setSection(frontFromPath)),
    store.dispatch(loadFront(vertical, frontFromPath)),
    broadcastSchedulesDataFetch,
  ];

  await Promise.all(promises);

  setLinkHeaders(ctx.res, vertical);
  return {
    fullUrl,
    navigating: false,
    pageView,
    ramenBentoAPISWRFallbackData: { [BROADCAST_SCHEDULES_ENDPOINT]: scheduleData },
  };
};

VideoLivePage.propTypes = {
  isChromeless: PropTypes.bool,
  layout: servicesLayoutPropType.isRequired,
  vertical: PropTypes.string.isRequired,
  ramenBentoAPISWRFallbackData: PropTypes.shape({
    [BROADCAST_SCHEDULES_ENDPOINT]: ScheduleDataPropType,
  }).isRequired,
  fullUrl: PropTypes.string.isRequired,
};

VideoLivePage.defaultProps = {
  isChromeless: false,
};

export default navbar(navbarConfig)(
  connect(mapStateToProps)(
    VideoLivePage,
  ),
);

// exported for testing
export { VideoLivePage as UnwrappedVideoLivePage };
